@import "./colors.less";

.App {
  text-align: center;
}

:root {
  --focus-border: @accent-400 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.selected {
  background-color: #99adf7;
}

.ActionButton.ant-btn-default {
  border-color: @neutral-800;
  color: @neutral-800;
  background-color: @neutral-100;
  &:disabled {
    border-color: @neutral-300;
    color: @neutral-400;
    background-color: @neutral-400;
  }
  &:hover:enabled {
    border-color: @accent-500;
    color: @accent-500;
    background: @accent-100;
  }
  &:active:enabled {
    border-color: @accent-500;
    color: @accent-500;
    background: @accent-100;
  }
}

.ActionButton.ant-btn-background-ghost {
  border-color: @neutral-800;
  color: @neutral-800;
  &:disabled {
    border-color: @neutral-300;
    color: @neutral-400;
  }
  &:hover:enabled {
    border-color: @accent-500;
    color: @accent-500;
    background: @neutral-100;
  }
  &:active:enabled {
    border-color: @accent-500;
    color: @accent-500;
    background: @accent-100;
  }
}

.BrandButton.ant-btn-primary {
  border-color: @accent-500;
  color: #ffffff;
  background: @accent-500;
  &:disabled {
    border-color: @neutral-400;
    color: #ffffff;
    background: @neutral-400;
  }
  &:hover:enabled {
    border-color: @accent-700;
    color: #ffffff;
    background: @accent-700;
  }
  &:active:enabled {
    border-color: @accent-600;
    color: #ffffff;
    background: @accent-600;
  }
}

.BrandButton.ant-btn-background-ghost {
  border-color: @neutral-600;
  color: @neutral-800;
  background: white;
  &:disabled {
    border-color: @neutral-300;
    color: @neutral-400;
    background: transparent;
  }
  &:hover:enabled {
    border-color: @accent-500;
    color: @accent-500;
    background: @accent-100;
  }
  &:active:enabled {
    border-color: @accent-500;
    color: @accent-500;
    background: @accent-100;
  }
}

.auto_hide_container .auto_hide {
  visibility: hidden;
}
.auto_hide_container:hover .auto_hide {
  visibility: unset;
}

.ant-layout-header input {
  background-color: #335bef;
  color: #fff;
  border: 1px solid #0018eb;
}

.ant-layout-header .ant-input-affix-wrapper {
  background-color: #335bef;
}

.ant-layout-header input::placeholder {
  color: #0018eb;
}

input.crcInput {
  background-color: transparent;
  color: #fff;
  caret-color: #fff;
}
.crcInput > input {
  background-color: transparent;
  color: #fff;
  caret-color: #fff;
}

label.ant-radio-button-wrapper:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
label.ant-radio-button-wrapper:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ant-spin-dot-item {
  background-color: fade(@accent-500, 86%);
}

.ant-tag,
.ant-select-selection-item {
  border-radius: 8px !important;
  align-items: center;
}
.ant-select-selection-item-remove {
  display: flex !important;
  align-items: center;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: rgba(0, 0, 0, 0.35);
}

.ant-switch {
  background-color: @neutral-800;
  &.ant-switch-checked {
    background-color: @accent-500;
  }
}

.CheckAll {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: @neutral-800;
  }
  .ant-checkbox-inner {
    border-color: @neutral-400;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: @neutral-400;
    background-color: @neutral-400;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    border-color: @neutral-800;
    &:hover {
      border-color: @neutral-400;
    }
    &::after {
      background-color: @neutral-400;
    }
  }
}

.PrimaryConcept {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: @neutral-800;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: #335bef;
    background-color: #335bef;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    border-color: @neutral-800;
    &:hover {
      border-color: #335bef;
    }
    &::after {
      background-color: #335bef;
    }
  }
}

.LinkedConcept {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: @neutral-800;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: #ffc043;
    background-color: #ffc043;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    border-color: @neutral-800;
    &:hover {
      border-color: #ffc043;
    }
    &::after {
      background-color: #ffc043;
    }
  }
}

.Relation {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: @neutral-800;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: #05944f;
    background-color: #05944f;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    border-color: @neutral-800;
    &:hover {
      border-color: #05944f;
    }
    &::after {
      background-color: #05944f;
    }
  }
}

.DefaultGray {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: @neutral-800;
  }
  .ant-checkbox-inner {
    border-color: @neutral-600;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: @neutral-600;
    background-color: @neutral-600;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    border-color: @neutral-800;
    &:hover {
      border-color: @neutral-600;
    }
    &::after {
      background-color: @neutral-600;
    }
  }
}

.Popup-selectionPopover {
  > .ant-popover-content {
    background-color: @neutral-200;
    border-radius: 12px;
    > .ant-popover-arrow {
      display: none;
    }
    > .ant-popover-inner {
      background-color: @neutral-200;
      border-radius: 12px;
      .ant-popover-title {
        padding: 0;
        border: none;
      }
      > .ant-popover-inner-content {
        padding: 0;
        border-radius: 0 0 12px 12px;
      }
    }
  }
}

/**
 * Hack way of targeting the evidence feedback
 * popover to remove the carat and box-shadow
 */
.feedback-popover {
  > .ant-popover-content {
    > .ant-popover-arrow {
      display: none;
    }
    > .ant-popover-inner {
      background-color: transparent;
      box-shadow: none;
      > .ant-popover-inner-content {
        padding: 0 8px;
      }
    }
  }
}

.tour-popover {
  > .ant-popover-content {
    > .ant-popover-inner {
      border-radius: 8px;
      > .ant-popover-inner-content {
        padding: 0px;
      }
    }
  }
}

.tour-popover {
  &:not(
      .ant-popover-placement-bottom,
      .ant-popover-placement-bottomRight,
      .ant-popover-placement-bottomLeft
    ) {
    > .ant-popover-content {
      > .ant-popover-arrow {
        > .ant-popover-arrow-content {
          &::before {
            background-color: #ffffff;
          }
        }
      }
    }
  }
}
