.cora-graph {
    flex: 1;
}
.cora-graph canvas {
    outline-color: #b089ff;
}

.cora-graph.cell {
    background-color: 1;
    width: 200px;
    height: 100px;
    flex: 1;
}
