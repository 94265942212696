@import "../../assets/css/diagramCommon.less";

.ConstraintsBar {
  //background-color: white;
  .ant-tabs-nav {
    margin-bottom: 0;
    font-weight: normal;
    .ant-tabs-tab.ant-tabs-tab-with-remove {
      display: flex;
      align-items: center;
      padding: 2px 4px;
      margin: 4px;
      font-size: 13px;
      color: @TEXT;
      border: none;
      background-color: transparent;

      .ant-tabs-tab-remove {
        margin-bottom: 6px;
        margin-left: 2px;
        visibility: hidden;
      }
      &:hover {
        .ant-tabs-tab-btn {
          background-color: @NODE_COLOR;
        }
        .ant-tabs-tab-remove {
          visibility: visible;
        }
      }

      .ant-tabs-tab-btn {
        border-bottom: @BORDER_WIDTH solid transparent;
        padding: 2px 4px;
      }
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          background-color: @ACTIVE_BACKGROUND_COLOR;
          color: @ACTIVE_COLOR;
          text-shadow: unset;
          border-bottom-color: @ACTIVE_COLOR;
        }
      }
    }

    .ant-tabs-nav-add {
      display: none;
    }

  }

}
