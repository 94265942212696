@import "../../colors.less";

.HeaderInput {
  color: white !important;
}

.HeaderInput::selection,
.HeaderInput > ::selection {
  background-color: #6684f3 !important;
}

.RadioGroupTabs {
  display: flex;
  flex: 1;
}
.RadioGroupTabs label.ant-radio-button-wrapper {
  flex: 1;
  background-color: transparent;
  color: gray;
}
.RadioGroupTabs > .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: @brand-500;
  border-color: @brand-500;
  border-right-color: @brand-500 !important;
}

.RadioGroupTabs
  > .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
  background-color: @brand-400;
  border-color: @brand-400;
  border-right-color: @brand-400 !important;
  border-right-width: 0 !important;
}
.FocusContainer {
  border-width: 1px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.FocusContainer > input:hover,
.FocusContainer > .ant-select:hover .ant-select-selector {
  border-color: transparent;
}
.FocusContainer
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.FocusContainer > input,
.FocusContainer > input:hover,
.FocusContainer
  > .ant-select-focused.ant-select
  .ant-select-selector
  .FocusContainer
  > .ant-select
  .ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.FocusContainer .ant-input {
  background-color: transparent;
  border-width: 0;
}
.FocusContainer > .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: transparent;

  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px !important;
  outline: 0;
}
.FocusContainer > .ant-input-affix-wrapper {
  background-color: transparent !important;
}
.FocusContainer > .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: transparent !important;
  box-shadow: none;
  //box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  //border-right-width: 1px !important;
  //outline: 0;
}
.FocusContainer
  > .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: transparent !important;
}
.FocusContainer > span.ant-select-clear:hover {
  background-color: transparent !important;
}
.ant-select-clear,
.CoraField > span.ant-select-clear:hover {
  background-color: transparent !important;
}

.ant-table-thead {
  & th.ant-table-cell {
    background-color: @accent-900;
    color: white;
    &.ant-table-column-has-sorters:hover {
      background-color: @accent-800;
    }
  }
}
