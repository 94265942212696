@import "../../../colors.less";

.cora-graph {
  flex: 1;
}
.cora-graph canvas {
  outline-color: @accent-500;
}

.cora-graph.cell {
  //background-color: 1;
  width: 200px;
  height: 100px;
  flex: 1;
}

.ModelGraph :focus-visible {
  outline-width: 0;
}
