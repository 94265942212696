@import "~react-datepicker/dist/react-datepicker.css";

@dark-selection: rgba(147, 66, 179, 0.75);
@light-selection: rgba(159, 73, 193, 0.15);
@dark-outline: #622f7c;
@light-outline: #f0f0f0;
@dark-text: black;
@light-text: white;

.react-datepicker {
    border: unset;
    border-radius: 8px;
}
.react-datepicker__input-container > input {
    width: 116px;
    padding: 0 8px;
}

.react-datepicker__input-container > input:focus-visible {
    outline: unset;
}

.react-datepicker__header {
    background-color: unset;
}

.react-datepicker__close-icon::after {
    background-color: #d4d4d8;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: @dark-selection;
    &:hover {
        background-color: @dark-selection;
    }
    &:focus-visible {
        outline: @dark-outline auto 1px;
    }
    &.react-datepicker__day--keyboard-selected,
    &.react-datepicker__month-text--keyboard-selected,
    &.react-datepicker__quarter-text--keyboard-selected,
    &.react-datepicker__year-text--keyboard-selected {
        background-color: @dark-selection;
        color: @light-text;
        outline: @light-outline auto 1px;
        &:focus-visible {
            outline: @dark-outline auto 1px;
        }
        &:hover {
            background-color: @light-outline;
        }
    }

}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
    background-color: @light-selection;
    color:@dark-text;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: unset;
    //color: inherit;
    outline: @light-outline auto 1px;
    &:focus-visible {
        outline: @dark-outline auto 1px;
    }
    &:hover {
        background-color: @light-outline;
        color: @dark-text;
    }
}

