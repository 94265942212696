#QuestionInput::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-style: italic;
}
#QuestionInput {
  color: rgba(255, 255, 255);
  font-style: normal;
}

.QuestionInputDropdown .ant-select-item-option-active {
  background-color: rgba(187, 187, 187, 0.5);
}
