@import "../../../colors.less";

.ApertureSlider > .ant-slider-rail {
  background: linear-gradient(
    90deg,
    #fff 0%,
    @accent-500 52.14%,
    #4c1e5f 102.16%
  );
}
